import { Link } from "react-router-dom";

export default function Gen() {
  return (
    <div className="home-body">
      <header className="header">
        <div className="container">
          <nav className="navbar">
            <span className="spacer"></span>
            <div>
              <Link to="/gen" className="primary">
                Reach continuum
              </Link>
            </div>
          </nav>
        </div>
      </header>

      <main className="main">
        <section className="gen">
          <div className="container">
            <h1 className="coming-soon">[Coming soon]</h1>
          </div>
        </section>
      </main>

      <div className="footer">
        <ul className="footer__social">
          <li>
            <a
              href="https://x.com/c8ntinuum"
              className="footer__social-icon icon-x"
              target="_blank"
              rel="noopener nofollow noreferrer">
              {" "}
            </a>
          </li>
          <li>
            <a
              href="https://t.me/c8ntinuumANN"
              className="footer__social-icon icon-telegram"
              target="_blank"
              rel="noopener nofollow noreferrer">
              {" "}
            </a>
          </li>
          {/* <li>
    <a
      href="#"
      className="footer__social-icon icon-discord"
      target="_blank"
      rel="noopener nofollow noreferrer">
      {" "}
    </a>
  </li> */}
        </ul>
        {/* <p className="footer__text">[ Coming soon ]</p> */}
      </div>
    </div>
  );
}

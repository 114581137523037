import React, { useCallback, useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import homeDetailsImage1 from "./../assets/img/home-details-1.svg";
import homeDetailsImage2 from "./../assets/img/home-details-2.svg";
import homeDetailsImage3 from "./../assets/img/home-details-3.svg";
import arrowLeft from "./../assets/img/arrow-left.svg";
import arrowRight from "./../assets/img/arrow-right.svg";
import leftAnimation from "../assets/img/animations/Thriving_1.json";
import middleAnimation from "../assets/img/animations/First_multichain_2.json";
import rightAnimation from "../assets/img/animations/Interactive_Staking_3.json";

// Example card data
const cardData = [
  {
    animation: leftAnimation,
    img: homeDetailsImage1,
    title: "Thriving on the limitless possibilities",
    description: "Created by connecting leading blockchain networks",
  },
  {
    animation: middleAnimation,
    img: homeDetailsImage2,
    title: "First multichain protocol owned treasury",
    description: "Designed for generating value continuously",
  },
  {
    animation: rightAnimation,
    img: homeDetailsImage3,
    title: "Interactive Staking",
    description:
      "Unique design encouraging active participation and engagement with the network",
  },
];

export function ExtraHomeDetails() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideshowRef = useRef<HTMLDivElement>(null);
  const slideIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const autoSlideInterval = 3000;
  const [isMobile, setIsMobile] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null); // Tracks hovered card index
  const animationRefs = useRef<(any | null)[]>([]);
  const [completedAnimations, setCompletedAnimations] = useState<boolean[]>(
    Array(cardData.length).fill(false)
  );

  const checkIfMobile = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, [checkIfMobile]);

  const moveToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
  }, []);

  const moveToPreviousSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
    );
  }, []);

  useEffect(() => {
    if (slideshowRef.current) {
      const scrollLeft = currentIndex * slideshowRef.current.clientWidth;
      slideshowRef.current.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  const startAutoSlide = useCallback(() => {
    clearInterval(slideIntervalRef.current as NodeJS.Timeout);
    slideIntervalRef.current = setInterval(() => {
      moveToNextSlide();
    }, autoSlideInterval);
  }, [moveToNextSlide]);

  const stopAutoSlide = useCallback(() => {
    if (slideIntervalRef.current) {
      clearInterval(slideIntervalRef.current);
      slideIntervalRef.current = null;
    }
  }, []);

  const handleArrowClick = (direction: "prev" | "next") => {
    if (isMobile) stopAutoSlide();
    if (direction === "next") moveToNextSlide();
    if (direction === "prev") moveToPreviousSlide();
    if (isMobile) startAutoSlide();
  };

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, [startAutoSlide, stopAutoSlide]);

  useEffect(() => {
    // Reset animation for the current slide on mobile
    if (isMobile) {
      setCompletedAnimations((prev) => {
        const newCompleted = [...prev];
        newCompleted[currentIndex] = false; // Reset animation for the current index
        return newCompleted;
      });
    }
  }, [currentIndex, isMobile]);

  const handleAnimationComplete = (index: number) => {
    setCompletedAnimations((prev) => {
      const newCompleted = [...prev];
      newCompleted[index] = true;
      return newCompleted;
    });
  };

  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
    setCompletedAnimations((prev) => {
      const newCompleted = [...prev];
      newCompleted[index] = false; // Reset animation on hover
      return newCompleted;
    });
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  return (
    <div className="extra-home-details w-100 relative">
      <div
        ref={slideshowRef}
        className="extra-details-container flex overflow-hidden">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="extra-details flex-shrink-0"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}>
            {isMobile ? (
              <div className="extra-details__animation">
                {currentIndex === index && !completedAnimations[index] ? (
                  <Lottie
                    animationData={card.animation}
                    play
                    loop={false}
                    style={{
                      height: "63px",
                      width: "120px",
                      marginBottom: "2rem",
                    }}
                    onComplete={() => handleAnimationComplete(index)}
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                ) : (
                  <img
                    src={card.img}
                    alt={card.title}
                    className="extra-details__image"
                  />
                )}
              </div>
            ) : (
              <div className="extra-details__animation">
                {hoverIndex === index && !completedAnimations[index] ? (
                  <Lottie
                    animationData={card.animation}
                    play
                    loop={false}
                    style={{
                      height: "63px",
                      width: "120px",
                      marginBottom: "2rem",
                    }}
                    onComplete={() => handleAnimationComplete(index)}
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                ) : (
                  <img
                    src={card.img}
                    alt={card.title}
                    className="extra-details__image"
                  />
                )}
              </div>
            )}

            <div className="extra-details__content">
              <p className="mb-2">
                <strong>{card.title}</strong>
              </p>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="arrows-container">
        <div className="arrows">
          <div
            className="arrows__left cursor-pointer"
            onClick={() => handleArrowClick("prev")}>
            <img src={arrowLeft} alt="Previous Slide" />
          </div>
          <div
            className="arrows__right cursor-pointer"
            onClick={() => handleArrowClick("next")}>
            <img src={arrowRight} alt="Next Slide" />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";

// Custom Dropdown Component
const CustomDropdown = ({
  options,
  selectedOption,
  onSelect,
  label,
}: {
  options: any;
  selectedOption: any;
  onSelect: any;
  label?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className="dropdown-container"
      ref={dropdownRef}
      style={{ width: "100%" }}>
      {/* Dropdown label */}
      {/* {label && <label className="dropdown-label">{label}</label>} */}

      {/* Selected option */}
      <div className="dropdown-selected" onClick={toggleDropdown}>
        {selectedOption ? selectedOption : label} <span>&#9660;</span>
      </div>

      {/* Dropdown options */}
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li key={index} className="dropdown-option">
              <a
                href={option.link} // Link for each option
                target="_blank"
                rel="noopener nofollow noreferrer"
                className="dropdown-link">
                {option.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
